import Vue from "vue";
import VueRouter from "vue-router";
import commonRoutesMap from "./commonRoutes";

Vue.use(VueRouter);

const files = require.context("../views", true, /router\.js$/);

var configArray = [];

files.keys().forEach(key => {
	if (key.indexOf("router.js") != -1) {
		console.log(key);
		configArray = configArray.concat(files(key).default);
	}
});

const router = new VueRouter({
	// mode: 'history',
	routes: configArray.concat(commonRoutesMap)
});

export default router;
