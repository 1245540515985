/**
 * @author wangxin
 * @description 所有接口api地址 
 */
const host = '';
let apiHost; //开发线域名
let smsHost = "https://sso.jxxhy.cn/";//短信接域名
let tokenInfo = {
  "grant_type": "client_credentials",
  "client_id": "homeschool.miniprogram",
  "client_secret": "secret",
  "scope": "UserAPI ClientInfo ScoreInfo"
};//token基础信息
const wxapiHost = 'https://api.weixin.qq.com/'; //微信接口域名
let uploadFileApiHost = 'https://upload.jxxhy.cn/'; //上传文件域名
//昌江
apiHost = 'https://cjsso.jxxhy.cn/';

const api = {
  KEY_SSOAccessToken: "SSOAccessToken",//存储ssoToken的key名称
  tokenInfo: tokenInfo,//token信息
  code2session: wxapiHost + 'sns/jscode2session',
  uploadFile: uploadFileApiHost + 'common/UploadFile?FormFile=FormFile', //上传文件
  uploadImage: uploadFileApiHost + 'common/UploadImage?FormFile=FormFile&width=100&height=100', //上传图片
  noticeList: apiHost + "noticeInfo/noticeInfo/notice/GetMyReceiveNoticeList", // 通知公告列表接口
  delNotice: apiHost + "noticeInfo/noticeInfo/notice/DelNoticeByIds", // 删除通知公告
  forIdNoticeDetail: apiHost + "noticeInfo/noticeInfo/notice/GetNoticeInfo", // 通知公告详情的接口
  searchNotice: apiHost + "noticeInfo/noticeInfo/notice/GetMyReceiveNoticeList", // 搜索通知公告
  askForLeaveList: apiHost + "fk/leave/leave_api/getLeaveList", // 请假界面的请假记录列表
  writeAskForLeave: apiHost + "fk/leave/leave_api/doLeave", // 写假条
  askForLeaveDetail: apiHost + "fk/leave/leave_api/getLeaveInfo", //根据id获取请假详情
  withdrawLeaveNote: apiHost + "fk/leave/leave_api/revokeLeave", //撤回假条
  feedback: apiHost + "fk/feedback/feedback_api/sendfeedback", //提交意见反馈
  bindStudentInfo: apiHost + "api/api/parent/bindStuParent", //绑定学生家长信息
  unBindStudentInfo: apiHost + "api/api/parent/UnBindStuParent", // 解绑
  addStudentInfo: apiHost + "api/api/parent/AddStuParent", //添加学生信息
  getClient: apiHost + "api/api/clients/getParentClients?AppType=2&IsSchoolSystem=0", //获取家长可用应用
  getParentByOpenId: apiHost + "api/api/parent/getParentByOpenId", //获取绑定信息，如果没有则是没有绑定
  changeStudent: apiHost + "api/api/parent/changeStuParent", //切换学生
  AccessToekn: apiHost + "classzone/api/MiniWechat/GetAccessToken", //获取AccessToken
  getChildList: apiHost + "api/api/parent/GetChildList", //根据家长id获取孩子列表
  CheckClassCode: apiHost + "api/api/parent/CheckClassCode", //验证班级编号
  CheckStuParentInfo: apiHost + "api/api/parent/CheckStuParentInfo", //验证学生家长信息
  myReceiveHomeWork: apiHost + "schoolContact/api/HomeWork/GetMyReceiveHomeWork", //获取我收到的作业（学生）
  setRead: apiHost + "schoolContact/api/HomeWork/SetRead", //设置作业已读
  submitHomeWork: apiHost + "schoolContact/api/HomeWork/SubmitHwAnswer", //提交作业
  homeWorkDetail: apiHost + "schoolContact/api/HomeWork/GetStuHwDetail", //获取作业详情
  deleteHomeWork: apiHost + "schoolContact/api/HomeWork/DeleteStuHomeWork", //删除作业
  publicClassCircle: apiHost + "classzone/api/ClassZone/CreateClassZone", //发布班级圈
  classCircleList: apiHost + "classzone/api/ClassZone/GetClassZoneByClassId", //获取班级圈列表
  clearNews: apiHost + "classzone/api/ClassZone/CancelUnRead", //清除未读消息提醒
  newsList: apiHost + "classzone/api/ClassZone/GetClassZoneCommentTotalByRetionMe", //获取未读消息列表
  cancelZan: apiHost + "classzone/api/ClassZone/DeleteZoneComment", //取消赞
  deleteClassCircle: apiHost + 'classzone/api/ClassZone/DeleteClassZone', //删除班级圈
  zanAndComment: apiHost + "classzone/api/ClassZone/PostZoneComment", //动态评论或点赞
  newsDetail: apiHost + "classzone/api/ClassZone/GetZoneByid", //获取消息详情
  byClassIdThumb: apiHost + "classzone/api/Photos/GetPhotosByClassId", // 根据学生id获取相册
  byThumbIdPhotos: apiHost + "classzone/api/Photos/GetPhotosByid", // 根据相册id获取照片集
  pageThumbPhotos: apiHost + "classzone/api/Photos/GetImageListByPhotoId", // 点击加载更多的接口
  toSchoolNewsForDate: apiHost + "facerec/api/hzsun/getByStuId", // 根据日期加载到校记录
  sendVerificationCode: smsHost + "sms/api/VerifyCode/Send", // 发送验证码
  verifyVerificationCode: smsHost + "sms/api/VerifyCode/Verify", // 验证手机接收到的验证码
  getAccessToken: apiHost + "core/connect/token", // 验证码操作需要获取的access_token
  msgcenter: apiHost + "msgcenter/api/push/{uid}",    //消息中心
}
export default api;