<template>
  <div id="app">
    <router-view class="router-view" v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script> 
export default { 
  data () { 
    return { 
      transitionName: 'slide-left'
    } 
  }, 
  watch: { 
    $route (to, from) {
        if (to.meta.index > from.meta.index) { 
          this.transitionName = 'slide-left' 
          console.log('slide-left') 
        } else if (to.meta.index < from.meta.index) { 
          this.transitionName = 'slide-right' 
          console.log('slide-right') 
        } else { 
          this.transitionName = '' 
        } 
    } 
  } 
} 
</script> 
  <style>
  #app {
    height: 100%;
    width: 100%;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

.slide-right-enter-active,
.slide-right-leave-active, 
.slide-left-enter-active, 
.slide-left-leave-active {
  top: 0;
  width: calc(100vw);
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter-from {
  z-index: 1;
  opacity: 0.4;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter-from {
  z-index: 0;
  opacity: 0.4;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
