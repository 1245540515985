

import request from '@/utils/request'
import store from "@/store"
import { Encrypt } from '@/utils/validate.js';
import common from '@/utils/commonapi.js'

const header = {'Content-Type': 'application/x-www-form-urlencoded'}
// 接口地址前缀域名之后共同的
const prefix = ''
/**
 * 请求的底层接口
 * @param {*} url  接口地址
 * @param {*} method 请求方法 get、post
 * @param {*} params  请求url上的参数
 * @param {*} data  请求body的参数
 * @param {*} headers  请求头
 */
export const baseRequest = (url, method = "post", params, data, headers) => {
    let _param = {
        url: (url.indexOf("http") == 0 ? url : prefix + url),
        method: method
    }
    let _header = {}
    params = params || {}
    //console.log('header tokent--111 ',store.getters.token);
    if (store.getters.token) {
        // let each request carry tokens
        let _token = store.getters.token;
        let _openid = store.getters.openid;
        //console.log('header tokent222-- ',_token);
        if(_openid){
            // _header['openid'] = Encrypt(_openid, true).toString();
            _header['openid'] = _openid;
        }
        if(_token){
            _header['Authorization'] = "Bearer " + _token;
        }
    }
    if (headers) {
        Object.assign(_header, headers)
    }
    _param.headers = _header
    // if (method == 'post') {
    //     _param.data = data
    //     // _param.data = qs.stringify(params)
    // }
    if(data){
        _param.data = data
    }
    if(params){
        _param.params = params
    }

    return request(_param);
}

/**
 * 获取配置文件
 * @param {*} query 
 */
export const getConfig = query => {
    // 缓存当天
    const _date = new Date();
    const _v = _date.getFullYear()+""+_date.getMonth()+""+_date.getDate();
    return request({
        url: 'data/config.json?v='+ _v,
        method: 'get',
        params: query
    });
};
/**
 * 获取可用应用
 * @param {*} query 
 */
export const getClientApp = (data) => {
    console.log(common)
    return baseRequest(common.getClient, "get", data, null);
};
/**
 * 根据学校id获取年级班级信息
 * @param {*} query 
 */
 export const getGradeClassBySchoolId = (data) => {
    return baseRequest('/Organize/GetClassList', "get", data, null);
};

// 获取字典列表 证件类型：IdCardType，民族：Nation，户口类型：HouseholdReg
export const getDictionaryListApi = (data) => {
    return baseRequest('/Organize/GetDictionaryList', "get", data, null);
};

