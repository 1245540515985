  /**
 * /*
 * 通用路由配置，需要放在配置项数组的末端
 *
 * @format
 */


export default [
    {
        path: '/',
        name: 'Index',
        meta:{index: 1},
        component: () => import(/* webpackChunkName: "Index" */ '../views/index/index.vue')
    },
    {
      path: '/tree',
      name: 'Tree',
      meta:{index: 2},
      component: () => import(/* webpackChunkName: "Index" */ '../components/tree/index')
  },
  {
    path: "/classifyselect",
    name: "ClassifySelect",
    component: () => import(/* webpackChunkName: "selectPeople2" */ "@/components/select-user/classifySelect"),
    meta: {
        keepAlive: true,
        title: '选择分类'
    }
  // },
  // {
  //     path: "/selectpeople",
  //     name: "SelectPeople",
  //     component: () => import(/* webpackChunkName: "selectPeople2" */ "@/components/select-user/index.vue"),
  //     meta: {
  //         keepAlive: true,
  //         title: '选择人员'
  //     }
  // },
  // {
  //     path: "/alreadyselect",
  //     name: "AlreadySelect",
  //     component: () => import(/* webpackChunkName: "selectPeople2" */ "@/components/select-user/alreadySelect.vue"),
  //     meta: {
  //         keepAlive: false,
  //         title: '已选人员'
  //     }
  // },
  // {
  //     path: "/unMathdeplist",
  //     name: "UnMathDepList",
  //     component: () => import(/* webpackChunkName: "unMathList" */ "@/components/select-user/unMathList.vue"),
  //     meta: {
  //         keepAlive: false,
  //         title: '未匹配组织'
  //     }
  }
  ]
  